.login-container {
  background-color: var(--secondary);
  height: 100vh;
}

.login-box {
  background-color: #fff;
  border-radius: 1rem;
  box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.5);
  -webkit-box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.5);
  -moz-box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.5);
}

@media (min-width: 1440px) {
  .login-box {
    padding: 2rem 5rem;
    width: 30%;
    max-height: 70%;
  }
}

@media (min-width: 1200px) and (max-width: 1440px) {
  .login-box {
    padding: 2rem 5rem;
    width: 40%;
    max-height: 65%;
  }
}

@media (min-width: 992px) and (max-width: 1200px) {
  .login-box {
    padding: 2rem 5rem;
    width: 45%;
    max-height: 65%;
  }
}

@media (min-width: 768px) and (max-width: 992px) {
  .login-box {
    padding: 2rem 5rem;
    width: 55%;
    max-height: 65%;
  }
}

@media (min-width: 576px) and (max-width: 768px) {
  .login-box {
    padding: 2rem 5rem;
    width: 70%;
    max-height: 65%;
  }
}

@media (min-width: 425px) and (max-width: 576px) {
  .login-box {
    padding: 2rem 3rem;
    width: 85%;
    max-height: 65%;
  }
}

@media (max-width: 425px) {
  .login-box {
    padding: 2rem;
    width: 90%;
    max-height: 65%;
  }
}

.login-box .input-group-text {
  background-color: #fff;
  color: #adb0cd;
  font-size: 1.2rem;
  border-right: none;
}

.login-box .log-form-input {
  background-color: #fff !important;
  color: #adb0cd !important;
  font-size: 1.2rem !important;
  border-left: none !important;
}

.login-box .log-form-label {
  color: #adb0cd !important;
}

.login-box .log-btn {
  background-color: var(--dark-redish);
  width: 100%;
  color: var(--secondary);
  font-size: 1.2rem;
  text-align: center;
}

.log-link-cont {
  color: #adb0cd !important;
  font-weight: 800;
}

.log-link {
  color: var(--dark-redish) !important;
}

/* .react-international-phone-input {
  border: none;
  width: 100%;
  padding: 6px 12px;
}

.react-international-phone-input-container {
  padding: 0;
} */

.signUpNumberInput::placeholder,
.signUpNumberInput {
  color: #adb0cd !important;
}

.react-tel-input input {
  width: 100% !important;
}
