.history {
  background-color: var(--dark-redish);
  border-radius: 1rem;
}

.history-text {
  color: var(--secondary);
}

.history-divider {
  background-color: var(--yellish);
  height: 2px;
  margin: 2px 0;
  width: 95%;
}

.team-person-img:hover {
  scale: 1.05;
}

.team-person-info:hover {
  text-decoration: underline;
}

@media (min-width: 992px) and (max-width: 1440px) {
  .mission-text {
    width: 75%;
    font-size: 1.2rem;
  }
}

@media (min-width: 1440px) {
  .mission-text {
    width: 75%;
    font-size: 1.5rem;
  }
}

@media (max-width: 992px) {
  .history-row {
    display: flex;
    flex-direction: column-reverse;
  }
  .mission-row {
    display: flex;
    flex-direction: column-reverse;
  }
  .mission-text {
    margin-top: 2rem;
  }
}

@media (min-width: 425px) and (max-width: 576px) {
  .history-text, .mission-text {
    font-size: 0.8rem;
  }
}

@media (max-width: 425px) {
  .history-text, .mission-text {
    font-size: 0.6rem;
  }
}


