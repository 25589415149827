.Testimonial-card {
  position: relative;
  display: inline-block;
  width: 90%;
  height: 400px;
  background-color: var(--primary);
  border-radius: 2rem;
}

.Testimonial-card::before {
  content: "";
  position: absolute;
  bottom: -10px;
  right: -10px;
  width: 100%; /* Adjust the width of the frame */
  height: 100%; /* Adjust the height of the frame */
  background-color: transparent; /* Adjust the color of the frame */
  border: 3px solid var(--primary);
  border-radius: 2rem;
}

.userImg-cont::before {
  content: "";
  position: absolute;
  bottom: -10px;
  right: -10px;
  width: 100%; /* Adjust the width of the frame */
  height: 100%; /* Adjust the height of the frame */
  background-color: transparent; /* Adjust the color of the frame */
  border: 3px solid var(--secondary);
  border-radius: 2rem;
}

.userImg {
  border-radius: 2rem;
}

.carousel-indicators {
  bottom: 45px !important;
}

.Testimonial-card-text {
  color: var(--secondary) ;
}

@media (max-width: 576px) {
  .Testimonial-card-text {
    font-size: 0.5rem;
  }
  .userImg-cont {
    height: 100%;
    position: relative;
    max-width: 150px;
  }
}

@media (min-width: 576px) {
  .userImg-cont {
    height: 100%;
    position: relative;
    max-width: 250px;
  }
}

@media (min-width: 576px) and (max-width: 768px) {
  .Testimonial-card-text {
    font-size: 0.7rem;
  }
}

@media (min-width: 768px) and (max-width: 1200px) {
  .Testimonial-card-text {
    font-size: 1rem;
  }

  .text-img {
    max-width: 30px;
  }
}

@media (min-width: 1200px) {
  .Testimonial-card-text {
    font-size: 1.3rem;
  }

  .text-img {
    max-width: 50px;
  }
}
