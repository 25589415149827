.title-cont p {
  text-align: center;
  color: var(--primary);
  font-weight: bold;
}

.title-cont div:first-of-type {
  display: flex;
  align-items: center;
  justify-content: start;
}

.title-cont div:last-of-type {
  display: flex;
  align-items: center;
  justify-content: end;
}

.title-cont div div {
  background-color: var(--redish);
  height: 3px;
  width: 80%;
}

@media (max-width: 425px) {
  .title-cont p {
    font-size: 0.6rem;
    letter-spacing: 0.01rem;
    margin: 0;
    padding: 0;
  }
}

@media (min-width: 425px) and (max-width: 576px) {
  .title-cont p {
    font-size: 0.8rem;
    letter-spacing: 0.01rem;
    margin: 0;
    padding: 0;
  }
}

@media (min-width: 576px) and (max-width: 768px) {
  .title-cont p {
    font-size: 1rem;
    letter-spacing: 0.01rem;
    margin: 0;
    padding: 0;
  }
}

@media (min-width: 768px) and (max-width: 992px) {
  .title-cont p {
    font-size: 0.65rem;
    letter-spacing: 0.1rem;
    margin: 0;
    padding: 0;
  }
}

@media (min-width: 992px) and (max-width: 1200px) {
  .title-cont p {
    font-size: 0.95rem;
    letter-spacing: 0.1rem;
  }
}

@media (min-width: 1200px) and (max-width: 1400px) {
  .title-cont p {
    font-size: 1rem;
    letter-spacing: 0.2rem;
  }
}

@media (min-width: 1400px) {
  .title-cont p {
    font-size: 1.2rem;
    letter-spacing: 0.2rem;
  }
}
