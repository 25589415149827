.review-card {
  background-color: var(--dark-redish);
  margin: 3rem 0;
  border-radius: 1rem;
  position: relative;
}

.review-card::before {
  content: "";
  position: absolute;
  bottom: -10px;
  right: -10px;
  width: 100%; /* Adjust the width of the frame */
  height: 100%; /* Adjust the height of the frame */
  background-color: transparent; /* Adjust the color of the frame */
  border-right: 3px solid var(--redish);
  border-bottom: 3px solid var(--redish);
  border-radius: 1rem;
  z-index: -1;
}

.review-card-user-info p {
  color: var(--secondary);
}

.review-card-btn:hover {
  scale: 1.1;
}

.modal-content {
  padding: 1rem 2rem;
  box-shadow: 0px 0px 22px 5px rgba(2, 4, 4, 0.6);
  -webkit-box-shadow: 0px 0px 22px 5px rgba(2, 4, 4, 0.6);
  -moz-box-shadow: 0px 0px 22px 5px rgba(2, 4, 4, 0.6);
}

.edit-rate-btn {
  background-color: var(--secondary);
  color: var(--primary);
  font-weight: 800;
}

.edit-rate-btn:hover {
  background-color: var(--primary);
  color: var(--secondary);
}

@media (min-width: 768px) {
  .edit-rate-btn {
    font-size: 0.7rem;
  }
}

@media (min-width: 576px) and (max-width: 768px) {
  .edit-rate-btn {
    font-size: 0.7rem;
  }
}

@media (max-width: 576px) {
  .edit-rate-btn {
    font-size: 0.5rem;
    padding: 5px 0;
  }
}
