.personal-info-edit {
  background-color: var(--dark-redish);
  border-radius: 2rem;
  color: var(--secondary);
}

.personal-info-name {
  color: var(--dark-redish);
  font-weight: 800;
  text-align: center;
  font-size: 1.1rem;
}

.order-info-label {
  font-weight: 800;
}

.order-info-value {
  font-weight: 800;
  color: var(--secondary);
  background-color: var(--dark-redish);
  border-radius: 2rem;
  padding: 1rem 1.5rem;
  margin: 0 auto;
}

@media (min-width: 576px) {
  .order-info-value {
    width: 50%;
  }
}

@media (max-width: 576px) {
  .order-info-value {
    width: 75%;
  }
}

.accountNumberInput {
  padding-top: 6px !important;
  padding-bottom: 6px !important;
  color: var(--primary) !important;
}

.account-btn {
  width: 20%;
}

.changePW-btn {
  background-color: var(--dark-redish);
  color: var(--secondary);
}

.changePW-btn:hover {
  background-color: var(--primary);
  color: var(--secondary);
  font-weight: 800;
}

.hidePassword {
  cursor: pointer;
}

.personal-info-img {
  aspect-ratio: 1;
}

.personal-info-img-cont {
  position: relative;
  display: inline-block;
}

.personal-info-img-modify {
  position: absolute;
  bottom: 10px;
  left: 50%;
  transform: translateX(-50%);
  padding: 0;
  margin: 0;
  display: none;
  z-index: 2;
}

.personal-info-img-modify:hover {
  scale: 1.1;
}

.personal-info-img-cont:hover .personal-info-img-modify {
  display: block;
}

/* .personal-info-img-cont:hover {
  opacity: 0.8;
} */

.personal-info-img-cont:hover::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.19); /* Adjust the opacity as desired */
  z-index: 1;
  border-radius: 50%;
}
