.smoke-gradient {
  background: radial-gradient(ellipse at center, #841315, #020404);
  height: 100vh;
}

.thank-page {
  color: var(--dark-secondary);
  letter-spacing: 0.1rem;
  text-align: center;
}

@media (min-width: 1440px) {
  .thank-page-title {
    font-size: 5.5rem;
    font-weight: 800;
  }

  .thank-page-subtitle {
    font-size: 2rem;
  }
}

@media (min-width: 1200px) and (max-width: 1440px) {
  .thank-page-title {
    font-size: 5rem;
    font-weight: 800;
  }

  .thank-page-subtitle {
    font-size: 2rem;
  }
}

@media (min-width: 992px) and (max-width: 1200px) {
  .thank-page-title {
    font-size: 4rem;
    font-weight: 800;
  }

  .thank-page-subtitle {
    font-size: 2rem;
  }
}

@media (min-width: 768px) and (max-width: 992px) {
  .thank-page-title {
    font-size: 3.5rem;
    font-weight: 800;
  }

  .thank-page-subtitle {
    font-size: 2rem;
  }
}

@media (min-width: 576px) and (max-width: 768px) {
  .thank-page-title {
    font-size: 2.5rem;
    font-weight: 800;
  }

  .thank-page-subtitle {
    font-size: 1.5rem;
  }
}

@media (max-width: 576px) {
  .thank-page-title {
    font-size: 2rem;
    font-weight: 800;
  }

  .thank-page-subtitle {
    font-size: 1rem;
  }
}

.thank-page-divider {
  width: 95%;
  height: 2px;
  background-color: var(--dark-redish);
  margin: 1rem 0;
}
