.orderPay-page {
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: center;
}

.orderPay-page .myButton-cont {
  width: 100%;
}

.orderSummary {
  background-color: var(--dark-redish);
  width: 100%;
  padding: 2rem;
  border-radius: 2rem;
  color: var(--secondary);
}

.orderSummary-divider {
  height: 2px;
  background-color: var(--yellish);
  margin: 1rem 0;
}

.orderSummary-item,
.orderSummary-fee,
.orderSummary-total {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

/* media querries */

@media (min-width: 1440px) {
  .orderSummary {
    font-size: 1.5rem;
  }
}

@media (min-width: 1200px) and (max-width: 1440px) {
  .orderSummary {
    font-size: 1.3rem;
  }
}

@media (min-width: 992px) and (max-width: 1200px) {
  .orderSummary {
    font-size: 1.1rem;
  }
}

@media (min-width: 768px) and (max-width: 992px) {
  .orderSummary {
    font-size: 1.1rem;
  }
}

@media (min-width: 576px) and (max-width: 768px) {
  .orderSummary {
    font-size: 1rem;
  }
}

@media (min-width: 425px) and (max-width: 576px) {
  .orderSummary {
    font-size: 0.8rem;
  }
}

@media (max-width: 425px) {
  .orderSummary {
    font-size: 0.7rem;
  }
}

.modal-title {
  color: var(--secondary) !important;
}

.btn-close {
  background-color: var(--secondary) !important;
}

/* Stripe form styles */

.stripe-pay-btn {
  color: var(--primary);
  background-color: var(--secondary);
  font-weight: 800;
  margin-top: 1rem;
}

@media (min-width: 576px) {
  .stripe-pay-btn {
    width: 50%;
    font-size: 1.2rem;
  }
}

@media (max-width: 576px) {
  .stripe-pay-btn {
    width: 75%;
    font-size: 1rem;
  }
}

.stripe-pay-btn:hover {
  background-color: var(--primary);
  color: var(--secondary);
}

/* PayPal form styles */

.paypal-button {
  /* Define your styles for the PayPal button */
  background-color: #ffc439;
  color: #fff;
  padding: 10px 20px;
  font-size: 16px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  padding: 5px 4rem;
  margin-top: 1rem;
}

.paypal-button:hover {
  /* Define hover styles */
  background-color: rgb(250, 175, 0);
}
