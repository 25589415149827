.footer {
  background-color: var(--dark-redish);
}

.footer-nav {
  list-style-type: none;
  color: var(--secondary);
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 0.8rem;
  padding: 0;
}

.footer-nav li:hover {
  background: var(--goldish);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  cursor: pointer;
}

.footer-btn {
  background: var(--goldish) !important;
  font-weight: 800;
  font-size: 0.8rem;
}

.testimonial-field p {
  color: var(--secondary);
}

.footer-small-text {
  color: var(--secondary);
  opacity: 0.7;
  font-size: 0.7rem;
  margin-top: 3rem;
}

.footer-divider {
  background: var(--goldish);
  height: 3px;
  margin-bottom: 3rem;
}

.modal-content {
  background-color: var(--dark-redish);
}

@media (max-width: 992px) {
  .testimonial-field {
    width: 100%;
  }
}

@media (max-width: 576px) {
  .footer-nav {
    font-size: 0.5rem;
  }

  .testimonial-field {
    font-size: 0.5rem;
  }

  .testimonial-field form input,
  .testimonial-field form button {
    font-size: 0.5rem;
  }

  .footer-small-text {
    font-size: 0.5rem;
  }
}

@media (min-width: 992px) {
  .testimonial-field {
    width: 70%;
  }
}
