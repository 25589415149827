.ProductsDashRow .product-img {
  max-width: 100px;
}

table {
  table-layout: fixed;
  border-color: var(--dark-redish) !important;
}

td {
  width: 1%;
  white-space: nowrap;
}

.table-head {
  background-color: var(--dark-redish);
  color: var(--secondary);
}

.add_prod_form label {
  color: var(--secondary);
}

.swal-confirm-button {
  background-color: var(--dark-redish) !important;
  color: var(--secondary) !important;
}
