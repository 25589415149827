.notFound-page {
  background-color: var(--primary);
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.notFound-notice {
    color: var(--secondary);
    font-weight: 800;
    letter-spacing: 0.2rem;
    font-size: 2rem;
}

.notFound-oops {
    color: var(--dark-redish);
    background-color: var(--secondary);
    padding: .5rem 1rem;
    font-size: 2.5rem;
    font-weight: 800;
    width: fit-content;
}