/* color variables */

:root {
  --primary: #020404;
  --secondary: #f5f5f5;
  --dark-secondary: #b7b7b7;
  --greenish: #89a6a9;
  --redish: #a07178;
  --dark-redish: #841315;
  --blueish: #5d5d81;
  --goldish: linear-gradient(
    180deg,
    #e5ba5d 0%,
    #e2b854 23.96%,
    #ffffd0 50%,
    #f4c96c 75.52%,
    #c2973b 100%
  );
  --yellish: #ffdd00;
}

/* font variables*/

@import url("https://fonts.googleapis.com/css2?family=Manrope:wght@400;800&display=swap");

/* general styles */

.App {
  overflow-x: hidden;
  font-family: "Manrope", sans-serif;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.App-no-footer {
  overflow-x: hidden;
  font-family: "Manrope", sans-serif;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: start;
}

a {
  text-decoration: none;
  color: var(--primary) !important;
}

a:hover {
  text-decoration: underline;
}

/*

@media (min-width: 1440px) {
  
}

@media (min-width: 1200px) and (max-width: 1440px) {
  
}

@media (min-width: 992px) and (max-width: 1200px) {
  
}

@media (min-width: 768px) and (max-width: 992px) {
  
}

@media (min-width: 576px) and (max-width: 768px) {
  
}

@media (min-width: 425px) and (max-width: 576px) {
  
}

@media (max-width: 425px) {
  
}

*/
