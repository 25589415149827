.cart-item-card {
  background-color: var(--dark-redish);
  border-radius: 2rem;
  padding: 1rem;
}

.cart-item-img img {
  border-radius: 2rem;
}

.card-item-quantity-cont {
  background: var(--goldish);
  border-radius: 0.7rem;
}

.cart-item-delete:hover {
  scale: 1.1;
  cursor: pointer;
}

.cart-item-info {
  color: var(--secondary);
}

@media (max-width: 425px) {
  .cart-item-info {
    font-size: 1rem;
  }
}

@media (min-width: 425px) and (max-width: 576px) {
  .cart-item-info {
    font-size: 1.2rem;
  }
}

.card-item-quantity {
  color: var(--primary);
}

.card-item-quantity-btn:disabled {
  border-color: transparent;
  color: black;
}